@charset "UTF-8";
/*** The new CSS Reset - version 1.0.0 (last updated 8.7.2021) ***/
/* ブラウザのUAスタイルシートのすべてのスタイルを削除します、displayは除く */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

/* box-sizingの優先値 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* リストのスタイル（箇条書き・番号）を削除します */
ol, ul {
  list-style: none;
}

/* 画像がコンテナを超えないようにするため */
img {
  max-width: 100%;
}

/* テーブルのセル間のスペースを削除します */
table {
  border-collapse: collapse;
}

*, :before, :after {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  font-size: 62.5%;
}

img {
  vertical-align: middle;
}

ol {
  list-style-position: inside;
}

strong {
  font-weight: bold;
  text-decoration: underline;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

body {
  font-size: 1.7rem;
  font-family: YakuHanMP_Noto, "Noto Serif JP", serif;
  color: #E2E2E2;
  line-height: 2;
  letter-spacing: 0.1em;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background: #181818;
}
@media (max-width: 959px) {
  body {
    font-size: 1.5rem;
  }
}
@media (max-width: 559px) {
  body {
    font-size: 1.4rem;
  }
}

::selection {
  background: #999;
  color: #fff;
}

input[type=button], input[type=submit], button {
  font-family: inherit;
  appearance: none;
  margin: 0;
  cursor: pointer;
  color: #fff;
}

input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email], textarea {
  appearance: none;
  border: 1px solid #999;
  font-size: 1.6rem;
  font-family: sans-serif;
  background: #282828;
  border-radius: 0;
  transition: background 0.2s linear;
  color: #fff;
}
input[type=text]:focus, input[type=number]:focus, input[type=tel]:focus, input[type=password]:focus, input[type=email]:focus, textarea:focus {
  background: #383838;
}

input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email] {
  padding: 0 8px;
  width: 100%;
  height: 40px;
}
@media (max-width: 559px) {
  input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email] {
    padding: 0 5px;
  }
}
input[type=text]:read-only, input[type=number]:read-only, input[type=tel]:read-only, input[type=password]:read-only, input[type=email]:read-only {
  background: none;
  border: none;
}

textarea {
  padding: 8px;
  width: 100%;
  height: 10.5em;
  line-height: 1.5;
}
@media (max-width: 559px) {
  textarea {
    padding: 5px;
  }
}
textarea:focus {
  background: #383838;
}

.input-notice {
  font-size: 1.2rem;
}
@media (max-width: 959px) {
  .input-notice {
    display: block;
    margin-top: 3px;
  }
}
@media (max-width: 559px) {
  .input-notice {
    font-size: 1rem;
  }
}

.input-box {
  display: inline-block;
  position: relative;
}
.input-box .placeholder {
  color: #555;
  position: absolute;
  left: 8px;
  top: 6px;
  z-index: 1;
  transition: all 0.15s ease;
}
.input-box input:focus + .placeholder, .input-box input:not(:placeholder-shown) + .placeholder {
  color: #E2E2E2;
  font-size: 1.1rem;
  top: -18px;
}
@media (max-width: 559px) {
  .input-box input:focus + .placeholder, .input-box input:not(:placeholder-shown) + .placeholder {
    font-size: 1rem;
  }
}

.select-wrap {
  position: relative;
  display: inline-block;
  max-width: 100%;
  background: #282828;
}
.select-wrap:after {
  content: "›";
  color: #fff;
  position: absolute;
  right: 8px;
  top: calc(50% - 0.5em);
  z-index: 1;
  line-height: 1;
  transform: rotate(90deg);
  z-index: 1;
  font-size: 1.4rem;
}
@media (max-width: 559px) {
  .select-wrap:after {
    right: 5px;
  }
}
.select-wrap:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 25px;
  top: 0;
  background: #999;
}
@media (max-width: 559px) {
  .select-wrap:before {
    right: 19px;
  }
}

select {
  margin: 0;
  max-width: 100%;
  font-family: sans-serif;
  appearance: none;
  height: 40px;
  line-height: 40px;
  border: 1px solid #999;
  padding: 0 33px 0 8px;
  position: relative;
  z-index: 2;
  background: none;
  font-size: 1.6rem;
}
@media (max-width: 559px) {
  select {
    padding: 0 25px 0 5px;
  }
}
select option {
  background: #282828;
}
select:focus {
  background: rgba(255, 255, 255, 0.07);
}

select::-ms-expand {
  display: none;
}

input[type=radio], input[type=checkbox] {
  margin-right: 5px;
}

.radio {
  display: inline-block;
  cursor: pointer;
  position: relative;
  line-height: 1;
  padding: 8px 5px 8px 27px;
  margin-right: 30px;
  margin-top: 0 !important;
  font-size: 1.6rem;
}
@media (max-width: 559px) {
  .radio {
    font-size: 1.4rem;
  }
}
.radio input {
  opacity: 0;
  appearance: none;
  position: absolute;
}
.radio input:checked + .outer .inner {
  opacity: 1;
  transform: scale(1);
}
.radio input:focus + .outer .inner {
  opacity: 0.5;
}
.radio input:disabled + .outer {
  background: #181818;
  border-color: #363636;
}
.radio .outer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
  margin: 0;
  display: block;
  border: 1px solid #999;
  border-radius: 50%;
  transition: border 0.2s linear;
  background: #282828;
}
.radio .inner {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  position: absolute;
  left: 6px;
  top: 6px;
  overflow: hidden;
  text-indent: -9999px;
  transition: opacity 0.1s linear, transform 0.3s cubic-bezier(0.1, 0.82, 0.1, 1);
  transform: scale(0.7);
}
.radio:hover .outer {
  border-color: rgba(153, 153, 153, 0.5);
}

.win .radio {
  padding: 13px 5px 13px 27px;
}
.win .radio .outer {
  top: 11px;
}

.checkbox {
  display: inline-block;
  cursor: pointer;
  position: relative;
  line-height: 1;
  padding: 8px 5px 8px 27px;
  margin-right: 30px;
  margin-top: 0 !important;
  font-size: 1.6rem;
}
@media (max-width: 559px) {
  .checkbox {
    font-size: 1.4rem;
  }
}
.checkbox input {
  opacity: 0;
  appearance: none;
  position: absolute;
}
.checkbox input:checked + .outer .inner {
  opacity: 1;
  transform: scale(1) rotate(-45deg);
}
.checkbox input:focus + .outer .inner {
  opacity: 0.5;
}
.checkbox input:disabled + .outer {
  background: #181818;
  border-color: #363636;
}
.checkbox .outer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
  margin: 0;
  display: block;
  border: 1px solid #999;
  border-radius: 2px;
  background: #282828;
  transition: border 0.2s linear;
}
.checkbox .inner {
  width: 10px;
  height: 7px;
  display: block;
  opacity: 0;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  left: 4px;
  top: 3px;
  overflow: hidden;
  text-indent: -9999px;
  transition: opacity 0.1s linear, transform 0.3s cubic-bezier(0.1, 0.82, 0.1, 1);
  transform: scale(0.7) rotate(-45deg);
}
.checkbox:hover .outer {
  border-color: rgba(153, 153, 153, 0.5);
}

.win .checkbox {
  padding: 13px 5px 13px 27px;
}
.win .checkbox .outer {
  top: 11px;
}

.req {
  font-weight: 500;
  font-size: 1.1rem;
  margin-left: 10px;
  line-height: 1;
  border-radius: 3px;
  letter-spacing: 0;
  display: inline-block;
  padding: 4px 6px;
  background: #D93732;
  color: #E2E2E2;
}
@media (max-width: 559px) {
  .req {
    font-size: 1rem;
    margin-left: 5px;
    border-radius: 1px;
    padding: 2px 4px 2px 3px;
  }
}

.register-name .input-group, .register-kana .input-group {
  width: 280px;
}
@media (max-width: 559px) {
  .register-name .input-group, .register-kana .input-group {
    margin-top: 15px;
  }
}
.register-name .input-box, .register-kana .input-box {
  width: 120px;
  margin-left: 20px;
}
.register-name .input-box:first-of-type, .register-kana .input-box:first-of-type {
  margin-left: 0;
}

.register-address .input-box {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
@media (max-width: 959px) {
  .register-address .input-box {
    display: block;
  }
}
.register-address .input-box:first-of-type {
  margin-top: 0;
}
.register-address .input-box label {
  width: 160px;
  font-size: 1.5rem;
}
@media (max-width: 959px) {
  .register-address .input-box label {
    display: block;
    margin-bottom: 5px;
  }
}
@media (max-width: 559px) {
  .register-address .input-box label {
    font-size: 1.2rem;
    margin-bottom: 3px;
  }
}
.register-address .input-box input {
  max-width: calc(100% - 160px);
}
@media (max-width: 959px) {
  .register-address .input-box input {
    max-width: 100%;
  }
}
.register-address #fpostal-code {
  width: 120px;
}

.register-birthday .select-wrap, .register-product-date .select-wrap {
  margin-right: 5px;
}
@media (max-width: 559px) {
  .register-birthday .select-wrap, .register-product-date .select-wrap {
    margin-right: 3px;
  }
}

@media (max-width: 959px) {
  .register-melmag {
    font-size: 1.4rem;
  }
}
.register-melmag td > div {
  margin-top: 20px;
}

.register-product-name td, .refresh_service_product td {
  position: relative;
}

.checkbox-list {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-list li {
  width: 33%;
  margin-top: 15px;
}
@media (max-width: 559px) {
  .checkbox-list li {
    width: 50%;
    margin-top: 10px;
  }
}
.checkbox-list li.long {
  width: 100%;
}
.checkbox-list li.long label {
  margin-right: 10px;
}

.chkgroup {
  display: inline-block;
}

#fpassword, #fckpassword {
  width: 300px;
}
@media (max-width: 959px) {
  #fpassword, #fckpassword {
    width: calc(100% - 80px);
  }
}

#ftel {
  width: 180px;
}

#fpurpose-other, #fpurpose-other-sports {
  width: 300px;
}
@media (max-width: 959px) {
  #fpurpose-other, #fpurpose-other-sports {
    width: 250px;
  }
}
@media (max-width: 559px) {
  #fpurpose-other, #fpurpose-other-sports {
    width: 100%;
  }
}

:placeholder-shown {
  color: #555;
}

::-webkit-input-placeholder {
  color: #555;
}

::-moz-placeholder {
  color: #555;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #555;
}

a {
  text-decoration: none;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
a:hover img {
  opacity: 0.7;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}

.tbdisp {
  display: none;
}

.spdisp {
  display: none;
}

@media (max-width: 959px) {
  .tbdisp {
    display: block;
  }
  .tbhide {
    display: none;
  }
}
@media (max-width: 559px) {
  .spdisp {
    display: block;
  }
  .sphide {
    display: none;
  }
}
.cf:after {
  content: "";
  display: block;
  clear: both;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}

.cl {
  clear: both;
}

.btn {
  width: 400px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  position: relative;
  background: #292D31;
  overflow: hidden;
  transition: all 0.2s linear;
}
@media (max-width: 959px) {
  .btn {
    background: #c9c9c9;
    color: #181818;
  }
}
@media (max-width: 559px) {
  .btn {
    width: 100%;
    height: 50px;
  }
}
.btn .btn-label {
  position: relative;
  z-index: 2;
  white-space: nowrap;
}
.btn:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  position: absolute;
  left: -100%;
  top: 0;
  transition: left 0.5s cubic-bezier(0.1, 0.82, 0.1, 1);
}
@media (max-width: 959px) {
  .btn:before {
    display: none;
  }
}
.btn:after {
  content: "";
  width: 36px;
  height: 1px;
  background: #ddd;
  position: absolute;
  right: 0;
  top: calc(50% - 0.5px);
  transition: transform 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform-origin: right center;
}
@media (max-width: 959px) {
  .btn:after {
    display: none;
  }
}
.btn:hover, .btn:focus {
  color: #181818;
  text-decoration: none;
}
.btn:hover:before, .btn:focus:before {
  left: 0;
}
.btn:hover:after, .btn:focus:after {
  transform: scaleX(0);
}
.btn.btn-cancel {
  background: none;
  color: #888;
  border: 1px solid #888;
}
.btn.btn-cancel:before, .btn.btn-cancel:after {
  display: none;
}
.btn.btn-cancel:hover, .btn.btn-cancel:focus {
  opacity: 0.7;
}
.btn.btn-back {
  font-size: 1.4rem;
  width: 260px;
  height: 45px;
}
.btn.btn-back:after {
  right: auto;
  left: 0;
}
@media (max-width: 559px) {
  .btn.btn-back {
    font-size: 1.2rem;
    width: 100%;
    height: 32px;
    background: #999;
  }
}

.btn-option {
  padding: 5px 10px;
  font-size: 1.5rem;
  background: #292D31;
  line-height: 1;
  transition: all 0.2s linear;
  display: inline-block;
}
@media (max-width: 959px) {
  .btn-option {
    background: #aaa;
    color: #181818;
  }
}
@media (max-width: 559px) {
  .btn-option {
    font-size: 1.3rem;
    padding: 5px 8px;
  }
}
.btn-option:hover, .btn-option:focus {
  background: #eee;
  color: #181818;
  text-decoration: none;
}
@media (max-width: 959px) {
  .btn-option:hover, .btn-option:focus {
    background: #aaa;
    color: #181818;
  }
}
.btn-option.view-password {
  font-size: 1.4rem;
  padding: 5px;
  margin-left: 10px;
}
@media (max-width: 559px) {
  .btn-option.view-password {
    font-size: 1.3rem;
    margin-left: 5px;
    letter-spacing: 0.05em;
  }
}
.btn-option.btn-add {
  position: relative;
  padding-left: 25px;
}
.btn-option.btn-add:before {
  content: "+";
  line-height: 1;
  font-size: 1.5rem;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.btn-option.btn-delete {
  position: relative;
  padding-left: 25px;
}
.btn-option.btn-delete:before {
  content: "×";
  line-height: 1;
  font-size: 1.2rem;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.btn-area > * {
  margin: 0 20px;
}
@media (max-width: 559px) {
  .btn-area {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
  .btn-area > * {
    margin: 15px 0 0;
  }
}

.link {
  position: relative;
  color: #999;
  transition: color 0.2s linear;
}
.link:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #999;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.link:hover {
  color: #ccc;
  text-decoration: none;
}
.link:hover:before {
  transform: scaleX(1);
  transform-origin: left center;
}

.l {
  font-size: 1.8rem;
  display: inline-block;
}

.s {
  font-size: 1.4rem;
  display: inline-block;
}

.xs {
  font-size: 1.2rem;
  display: inline-block;
}

@media (max-width: 559px) {
  .xs {
    font-size: 1rem;
  }
  .s {
    font-size: 1.2rem;
  }
  .l {
    font-size: 1.6rem;
  }
}
.red {
  color: #D93732;
}

.block {
  margin-top: 80px;
}
@media (max-width: 559px) {
  .block {
    margin-top: 40px;
  }
}
.block:first-child {
  margin-top: 0;
}
.block > * + * {
  margin-top: 2em;
}
.block h2, .block h3, .block h4, .block h5, .block h6 {
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
}
.block h2 {
  font-size: 3.2rem;
}
@media (max-width: 559px) {
  .block h2 {
    font-size: 2.1rem;
  }
}
.block h3 {
  font-size: 1.9rem;
}
@media (max-width: 559px) {
  .block h3 {
    font-size: 1.7rem;
  }
}
.block h4 {
  font-size: 1.7rem;
}
@media (max-width: 559px) {
  .block h4 {
    font-size: 1.6rem;
  }
}
.block ul:not([class]) li {
  padding-left: 15px;
  position: relative;
  margin-top: 0.5em;
}
.block ul:not([class]) li:before {
  content: "";
  width: 4px;
  height: 4px;
  display: block;
  background: #181818;
  position: absolute;
  left: 2px;
  top: calc(0.9em - 2px);
}
.block ol:not([class]) li {
  margin-top: 5px;
}
.block img {
  max-width: 100%;
  height: auto;
}
.block .mypage-tit {
  font-size: 2.1rem;
}
.block .mypage-tit .s {
  font-size: 1.8rem;
}
.block .register-tit {
  font-size: 3.2rem;
  text-align: center;
}
@media (max-width: 559px) {
  .block .register-tit {
    font-size: 2.1rem;
  }
}

.box {
  border: 1px solid #888;
  padding: 1.5em;
}
.box > :last-child {
  margin-bottom: 0;
}
.box > * + * {
  margin-top: 1em;
}

table {
  width: 100%;
  font-size: 1.7rem;
  line-height: 1.5;
}
table th, table td {
  padding: 24px 0;
  vertical-align: top;
  text-align: left;
}
table th {
  font-weight: 500;
  color: #fff;
}
table thead th {
  text-align: center;
}
table .input-tr th {
  padding-top: 30px;
}
table.list-tb tr:nth-child(2n) {
  background: #222;
}
table.list-tb th, table.list-tb td {
  border: 1px solid #363636;
  padding: 16px 10px;
}
table.list-tb thead th {
  background: #444;
  padding: 8px 0;
  font-size: 1.4rem;
}
table.list-tb .list-check {
  vertical-align: middle;
  padding: 0;
  text-align: center;
  width: 50px;
  padding-top: 5px;
}
@media (max-width: 959px) {
  table.list-tb .list-check {
    width: 36px;
  }
}
table.list-tb .list-check .checkbox {
  margin: 0;
  padding: 0;
}
table.list-tb .list-check .checkbox .outer {
  position: static;
  display: inline-block;
}
table.list-tb .list-check .checkbox .inner {
  left: 5px;
  top: 5px;
}

.status-list {
  margin-top: 0;
}
.status-list li {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 10px;
  margin-top: 1em;
}
@media (max-width: 559px) {
  .status-list li {
    grid-template-columns: 1fr;
    gap: 0;
    font-size: 1.2rem;
  }
}
.status-list li span {
  font-weight: 600;
}

.list-option-btn {
  margin-top: 10px;
}

@media (max-width: 559px) {
  table {
    font-size: 1.4rem;
  }
  table:not(.nobreak) th, table:not(.nobreak) td {
    display: block;
    border: none;
    padding: 0 !important;
    width: 100%;
  }
  table:not(.nobreak) tr {
    display: block;
    border-bottom: 1px solid #363636;
    padding: 16px 0;
  }
  table:not(.nobreak) tr:last-child {
    border: none;
  }
  table:not(.nobreak) td {
    margin-top: 5px;
  }
}
hr {
  border: none;
  border-bottom: 1px solid #363636;
  margin: 50px auto;
}

.paginate {
  padding-top: 64px;
  width: 960px;
  margin: 0 auto;
}
@media (max-width: 959px) {
  .paginate {
    width: 100%;
  }
}

.paginate-text {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
}
@media (max-width: 559px) {
  .paginate-text {
    font-size: 1rem;
  }
}

.paginate-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.paginate-list li {
  width: 42px;
  padding-left: 0;
  margin: 0;
}
@media (max-width: 559px) {
  .paginate-list li {
    width: 30px;
  }
}
.paginate-list li:before {
  display: none;
}
.paginate-list a {
  display: block;
  text-align: center;
  line-height: 36px;
  height: 36px;
  letter-spacing: 0;
  color: #E2E2E2;
  transition: background 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
@media (max-width: 559px) {
  .paginate-list a {
    font-size: 1.2rem;
    line-height: 28px;
    height: 28px;
  }
}
.paginate-list a:hover {
  background: #383838;
  text-decoration: none;
}
.paginate-list .current a {
  background: #282828;
  pointer-events: none;
}

.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.modal.modal-open {
  opacity: 1;
  visibility: visible;
}
.modal-window {
  width: 800px;
  background: #181818;
  padding: 80px 60px;
  border-radius: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(255, 255, 255, 0.5) 0 0 30px 0;
}
.modal-tit {
  text-align: center;
  font-size: 2.1rem;
}
.modal-text {
  margin-top: 1.5em;
}

.modal-register-check .modal-text {
  text-align: center;
}
.modal-register-check .btn {
  width: 300px;
}

.complete-box {
  width: 700px;
  margin: 60px auto 0;
  border: 1px solid #888;
  padding: 60px;
}
@media (max-width: 959px) {
  .complete-box {
    width: 100%;
  }
}
@media (max-width: 559px) {
  .complete-box {
    padding: 25px;
  }
}
.complete-box .complete-tit {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 559px) {
  .complete-box .complete-tit {
    font-size: 1.8rem;
  }
}
.complete-box .complete-text {
  text-align: center;
}
.complete-box > p {
  margin-top: 1.5em;
}

.bn-password_edit_send .complete-box .complete-text, .bn-mail_edit_complete .complete-box .complete-text {
  text-align: left;
}

.error-box {
  width: 700px;
  margin: 0 auto;
  /*.error-list{
  	@include for-tb(){
  		font-size: 1.3rem;
  	}
  	li{
  		padding-left: 15px;position: relative;line-height: 1.5;margin-top: 15px;
  		&:before{
  			content:"・"; width: 15px;
  			position: absolute;left:0;top:0;
  		}

  	}
  }*/
}
@media (max-width: 959px) {
  .error-box {
    width: 100%;
  }
}
.error-box .error-tit {
  font-size: 2.1rem;
}
.error-box .error-text {
  text-align: center;
}
@media (max-width: 959px) {
  .error-box .error-text {
    text-align: left;
    margin-top: 1.5em;
    font-size: 1.3rem;
  }
}

#mypage .error-box {
  margin: 60px auto 0;
  padding: 30px 0 60px;
}
@media (max-width: 959px) {
  #mypage .error-box {
    margin: 0 auto;
  }
}
#mypage .error-box .error-tit {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 30px;
  padding-top: 50px;
  position: relative;
}
@media (max-width: 959px) {
  #mypage .error-box .error-tit {
    font-size: 2.1rem;
  }
}
#mypage .error-box .error-tit:before {
  content: "！";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #D93732;
  font-size: 1.8rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  color: #D93732;
}
#mypage .error-box > p {
  margin-top: 1.5em;
}

.anm {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: top 0.7s cubic-bezier(0.1, 0.82, 0.1, 1) 0.1s, opacity 0.4s linear 0.1s;
}
.anm.is-show {
  top: 0;
  opacity: 1;
}

.paginate p {
  margin-bottom: 15px;
}
.paginate ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.paginate ul li {
  margin: 0 5px;
  padding-left: 0;
}
.paginate .current a {
  background: #292d31;
}
.paginate a {
  border: 1px solid #292d31;
  color: #fff;
  display: block;
  padding: 5px 0 6px;
  min-width: 25px;
  text-align: center;
  line-height: 1;
  font-size: 1.2rem;
}
.paginate a:hover {
  background: #292d31;
  color: #fff;
  border-style: solid;
  text-decoration: none;
}

.square {
  padding-left: 1.2em;
  position: relative;
}
.square:before {
  content: "■";
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
}

.mt-05 {
  margin-top: 0.5em;
}

.law-area {
  text-align: center;
  display: block;
  padding-bottom: 20px;
}

.dl-content dl {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 40px 30px;
  line-height: 2;
  flex-wrap: wrap;
}
@media (max-width: 959px) {
  .dl-content dl {
    display: block;
    padding: 0;
  }
}
.dl-content dl dt {
  width: 180px;
  font-weight: 600;
}
@media (max-width: 959px) {
  .dl-content dl dt {
    width: 100%;
  }
}
.dl-content dl dd {
  width: calc(100% - 180px);
}
@media (max-width: 959px) {
  .dl-content dl dd {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .dl-content dl:not(:first-child) {
    padding-top: 20px;
  }
}

#wrapper {
  width: 100%;
  min-width: 1000px;
}

@media (max-width: 959px) {
  #wrapper {
    min-width: 0;
  }
}
header {
  position: relative;
  text-align: center;
}

.logo {
  width: 59px;
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}
.logo img {
  width: 100%;
  height: auto;
}

.head-navi {
  position: absolute;
  right: 30px;
  top: 35px;
  font-size: 1.3rem;
  line-height: 1;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 959px) {
  .head-navi {
    right: 25px;
    font-size: 1.2rem;
    display: none;
  }
}
.head-navi li {
  margin-left: 50px;
}
.head-navi li.logout {
  padding-left: 25px;
  border-left: 1px solid #888;
}
.head-navi li a {
  display: inline-block;
  padding: 5px 0;
  color: #fff;
}
@media (max-width: 959px) {
  .head-navi li {
    margin-left: 25px;
  }
}

.user-info {
  position: absolute;
  right: 30px;
  top: 15px;
  font-size: 1.3rem;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 1.3rem;
}
@media (max-width: 959px) {
  .user-info {
    position: initial;
    display: block;
    right: auto;
    top: auto;
    width: 80%;
    margin: 0 auto;
    line-height: 1.5rem;
    text-align: left;
    font-size: 1.2rem;
  }
}

@media (max-width: 959px) {
  .tbdisp-id {
    display: block;
  }
}

#menu-btn {
  display: none;
}

@media (max-width: 959px) {
  #menu-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: fixed;
    right: 15px;
    top: 10px;
    cursor: pointer;
    z-index: 999;
    overflow: hidden;
  }
  #menu-btn:before {
    content: "MENU";
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.05em;
    width: 100%;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  #menu-btn .menu-line {
    display: block;
    width: 30px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: calc(50% - 15px);
  }
  #menu-btn .menu-line:nth-child(1) {
    top: 17px;
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
  }
  #menu-btn .menu-line:nth-child(2) {
    top: 24px;
    transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  }
  #menu-btn .menu-line:nth-child(3) {
    top: 31px;
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
  }
  #menu.open #menu-btn .menu-line:nth-child(1) {
    top: 24px;
    transform: rotate(-135deg);
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  }
  #menu.open #menu-btn .menu-line:nth-child(2) {
    transform: scaleX(0);
  }
  #menu.open #menu-btn .menu-line:nth-child(3) {
    top: 24px;
    transform: rotate(135deg);
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  }
  #menu.open #menu-btn:before {
    content: "CLOSE";
  }
  #menu.open .navi-wrap {
    visibility: visible;
    opacity: 1;
  }
  .navi-wrap {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 100%;
    height: 100%;
    padding: 60px 0;
    background: rgba(68, 68, 68, 0.9);
    backdrop-filter: blur(3px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
  }
}
@media (max-width: 559px) {
  #menu-btn {
    right: 5px;
  }
  .navi-wrap {
    padding: 40px 0;
  }
}
.navi-wrap {
  width: 100%;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}

.gnavi-list {
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 1;
}
@media (max-width: 959px) {
  .gnavi-list {
    display: block;
  }
}
@media (max-width: 559px) {
  .gnavi-list {
    font-size: 1.5rem;
  }
}
.gnavi-list li {
  padding-left: 10px;
  position: relative;
}
@media (max-width: 959px) {
  .gnavi-list li {
    margin-top: 30px;
    padding-left: 0;
  }
}
@media (max-width: 559px) {
  .gnavi-list li {
    margin-top: 15px;
  }
}
.gnavi-list li:before {
  content: "/";
  color: #666;
  position: absolute;
  left: 0;
  top: 13px;
}
@media (max-width: 959px) {
  .gnavi-list li:before {
    display: none;
  }
}
.gnavi-list li:first-child {
  padding-left: 0;
}
.gnavi-list li:first-child:before {
  display: none;
}
.gnavi-list a {
  display: block;
  padding: 14px;
  position: relative;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
@media (max-width: 959px) {
  .gnavi-list a {
    padding: 10px 0;
  }
}
.gnavi-list a:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: calc(50% - 4.5px);
  bottom: -5px;
  transition: transform 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93), background 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
  transform: scale(0);
}
@media (max-width: 959px) {
  .gnavi-list a:before {
    display: none;
  }
}
.gnavi-list a:hover {
  text-decoration: none;
  color: #999;
}
.gnavi-list a:hover:before {
  background: #888;
  transform: scale(1);
}
.gnavi-list .active a:before {
  background: #fff;
  transform: scale(1);
}

.snavi-list {
  display: none;
}
@media (max-width: 959px) {
  .snavi-list {
    display: block;
    line-height: 1;
  }
  .snavi-list li {
    margin-top: 30px;
  }
  .snavi-list a {
    color: #E2E2E2;
    display: block;
    padding: 10px 0;
  }
  .snavi-list a:before {
    display: none;
  }
  .snavi-list .logout {
    font-size: 1.4rem;
    position: relative;
    margin-top: 60px;
  }
  .snavi-list .logout:before {
    content: "";
    width: 50px;
    height: 1px;
    background: #777;
    position: absolute;
    left: calc(50% - 25px);
    top: -30px;
  }
  .snavi-list .logout a {
    color: #ccc;
  }
}
@media (max-width: 559px) {
  .snavi-list {
    font-size: 1.5rem;
  }
  .snavi-list li {
    margin-top: 10px;
  }
  .snavi-list .logout {
    font-size: 1.2rem;
  }
}

article {
  width: 960px;
  margin: 0 auto;
  padding: 70px 0;
}
@media (max-width: 959px) {
  article {
    width: 80%;
  }
}
@media (max-width: 559px) {
  article {
    padding: 40px 0;
  }
}

footer {
  padding: 100px 0 30px;
}
@media (max-width: 559px) {
  footer {
    padding: 40px 0 20px;
  }
}

.copyright {
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.05em;
  display: block;
  text-align: center;
}
.copyright a {
  color: #999;
}

.copymark {
  font-family: Verdana, "Droid Sans";
}

#login, #register {
  background: url(../images/bg.jpg) center top no-repeat #181818;
  background-size: 1366px 832px;
}
@media (max-width: 559px) {
  #login, #register {
    background-size: 683px 416px;
  }
}

#login header, #register header, #faq header {
  height: 200px;
  padding-top: 40px;
}
@media (max-width: 559px) {
  #login header, #register header, #faq header {
    padding-top: 10px;
    height: 90px;
  }
}
#login .logo, #register .logo, #faq .logo {
  width: 119px;
}
@media (max-width: 559px) {
  #login .logo, #register .logo, #faq .logo {
    width: 70px;
  }
}
#login .head-navi, #register .head-navi, #faq .head-navi {
  right: 50px;
  top: 50px;
  display: flex;
}
@media (max-width: 959px) {
  #login .head-navi, #register .head-navi, #faq .head-navi {
    right: 25px;
  }
}
@media (max-width: 559px) {
  #login .head-navi, #register .head-navi, #faq .head-navi {
    width: 100%;
    font-size: 1.1rem;
    right: 0;
    top: 40px;
    justify-content: space-between;
  }
  #login .head-navi li, #register .head-navi li, #faq .head-navi li {
    margin-left: 0;
  }
  #login .head-navi li a, #register .head-navi li a, #faq .head-navi li a {
    padding: 0 20px;
  }
}
#login article, #register article, #faq article {
  padding-top: 20px;
}

#login .login-tit {
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.3;
  color: #fff;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
}
@media (max-width: 559px) {
  #login .login-tit {
    font-size: 1.8rem;
  }
}
#login .login-box {
  width: 400px;
  margin: 80px auto 0;
}
@media (max-width: 559px) {
  #login .login-box {
    width: 100%;
    margin-top: 50px;
  }
}
#login .login-tb {
  margin-top: 40px;
}
#login .login-tb th, #login .login-tb td {
  padding: 12px 0;
}
#login .login-tb th {
  width: 145px;
  padding-top: 18px;
}
#login .login-btn {
  text-align: center;
  margin-top: 24px;
}
#login .login-password-remind {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 24px;
}
#login .reminder-tit {
  font-size: 2.8rem;
  text-align: center;
}
@media (max-width: 559px) {
  #login .reminder-tit {
    font-size: 2.1rem;
  }
}
#login .reminder-text {
  text-align: center;
}
@media (max-width: 559px) {
  #login .reminder-text {
    text-align: left;
  }
}
#login .reminder-tb {
  width: 900px;
  margin: 0 auto;
}
@media (max-width: 959px) {
  #login .reminder-tb {
    width: 100%;
  }
}
#login .reminder-tb th {
  width: 280px;
}
#login hr {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 559px) {
  .info-list {
    width: 100vw;
    margin-left: -10vw;
  }
}
.info-list li {
  border-bottom: 1px solid #363636;
}
.info-list a {
  display: block;
  padding: 15px 40px 15px 20px;
  position: relative;
  transition: background 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93), color 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
@media (max-width: 559px) {
  .info-list a {
    padding: 10px 30px 10px 20px;
  }
}
.info-list a:hover {
  background: #222;
  text-decoration: none;
  color: #999;
}
.info-list a:before {
  content: "";
  width: 10px;
  height: 17px;
  background: url(../images/icon_arrow.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 15px;
  top: calc(50% - 8.5px);
}
@media (max-width: 559px) {
  .info-list a:before {
    width: 7px;
    height: 12px;
    top: calc(50% - 6px);
  }
}
.info-list a.nolink {
  pointer-events: none;
}
.info-list a.nolink:before {
  display: none;
}
.info-list-date {
  font-size: 1.3rem;
  line-height: 1;
  display: block;
}
@media (max-width: 559px) {
  .info-list-date {
    font-size: 1rem;
  }
}
.info-list-tit {
  line-height: 1.5;
  display: block;
  margin-top: 5px;
}
@media (max-width: 559px) {
  .info-list-tit {
    font-size: 1.3rem;
  }
}

#register hr {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}
#register .register-text {
  text-align: center;
}
@media (max-width: 559px) {
  #register .register-text {
    text-align: left;
  }
}
#register .register-check {
  display: flex;
  align-items: center;
  width: 900px;
  margin: 40px auto 0;
  border: 1px solid #363636;
  padding: 35px 0;
}
@media (max-width: 959px) {
  #register .register-check {
    width: 100%;
    flex-direction: column-reverse;
  }
}
@media (max-width: 559px) {
  #register .register-check {
    border: none;
    padding: 0;
  }
}
#register .register-check-left {
  width: 400px;
  text-align: center;
  border-right: 1px solid #363636;
}
@media (max-width: 959px) {
  #register .register-check-left {
    width: 100%;
    padding-top: 30px;
    border-right: none;
    border-top: 1px solid #363636;
  }
}
#register .register-check-right {
  width: calc(100% - 400px);
  padding: 0 40px;
}
@media (max-width: 959px) {
  #register .register-check-right {
    width: 400px;
    padding: 0 0 40px;
  }
}
@media (max-width: 559px) {
  #register .register-check-right {
    width: 100%;
  }
}
#register .register-check-tit {
  font-size: 1.5rem;
}
#register .register-check-image {
  margin-top: 20px;
}
@media (max-width: 559px) {
  #register .register-check-image {
    width: 240px;
    margin: 10px auto 0;
  }
}
#register .register-check-tb th {
  letter-spacing: 0.05em;
}
#register .register-check-tb th, #register .register-check-tb td {
  padding: 9px 0;
}
#register .register-check-tb th {
  padding-top: 15px;
}
@media (max-width: 559px) {
  #register .register-check-tb .input-notice {
    display: inline-block;
  }
}
#register .register-check-input {
  width: 174px;
  margin-right: 10px;
  letter-spacing: 0.4em;
  text-indent: 0.4em;
  padding-left: 12px;
}
#register .register-check-btn {
  margin-top: 24px;
}
#register .register-check-caution {
  display: flex;
  width: 900px;
  margin: 60px auto 0;
}
@media (max-width: 959px) {
  #register .register-check-caution {
    width: 100%;
  }
}
@media (max-width: 559px) {
  #register .register-check-caution {
    display: block;
  }
}
#register .register-check-caution-tit {
  padding-left: 60px;
  width: 290px;
  font-size: 2.4rem;
  background: url(../images/icon_check_caution.png) 0 12px no-repeat;
  background-size: 51px;
}
@media (max-width: 959px) {
  #register .register-check-caution-tit {
    width: 220px;
    line-height: 1.2;
    font-size: 2.1rem;
    padding-right: 10px;
    background-position: 0 5px;
  }
}
@media (max-width: 559px) {
  #register .register-check-caution-tit {
    width: 100%;
    font-size: 1.8rem;
    padding: 0 0 5px 45px;
    background-position: 0 6px;
    background-size: 36px;
  }
}
#register .register-check-caution-tit .s {
  font-size: 1.4rem;
  line-height: 1;
  display: inline-block;
}
@media (max-width: 959px) {
  #register .register-check-caution-tit .s {
    line-height: 1.2;
  }
}
@media (max-width: 959px) {
  #register .register-check-caution-tit .s {
    font-size: 1.2rem;
  }
}
#register .register-check-caution-list {
  width: calc(100% - 290px);
  margin-top: 10px;
  font-size: 1.4rem;
}
@media (max-width: 959px) {
  #register .register-check-caution-list {
    font-size: 1.3rem;
    width: calc(100% - 220px);
  }
}
@media (max-width: 559px) {
  #register .register-check-caution-list {
    width: 100%;
    font-size: 1.2rem;
  }
}
#register .register-check-caution-list li {
  padding-left: 20px;
  line-height: 1.8;
  margin-top: 10px;
}
#register .register-check-caution-list li:before {
  content: "・";
  margin-left: -20px;
  width: 20px;
  display: inline-block;
}
#register .register-check-caution-list li:first-child {
  margin-top: 0;
}
#register .register-select {
  display: flex;
  margin-top: 60px;
  flex-flow: column;
  align-items: center;
}
@media (max-width: 959px) {
  #register .register-select {
    display: block;
  }
}
#register .register-select-box {
  width: 470px;
  border: 1px solid #888;
  padding: 36px 36px 110px;
  position: relative;
  background: #181818;
}
@media (max-width: 959px) {
  #register .register-select-box {
    width: 560px;
    margin: 0 auto;
    padding-bottom: 36px;
  }
}
@media (max-width: 559px) {
  #register .register-select-box {
    width: 100%;
    padding: 24px 24px 0;
  }
}
#register .register-select-tit {
  text-align: center;
  padding-top: 85px;
}
#register .register-select-text {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-top: 32px;
}
@media (max-width: 559px) {
  #register .register-select-text {
    margin-top: 16px;
    font-size: 1.4rem;
  }
}
#register .register-select-list {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 20px;
}
@media (max-width: 559px) {
  #register .register-select-list {
    font-size: 1.3rem;
  }
}
#register .register-select-list li {
  margin-top: 10px;
  padding-left: 1.2em;
  position: relative;
}
#register .register-select-list li:before {
  content: "■";
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
}
#register .register-select-btn {
  position: absolute;
  left: 36px;
  bottom: 20px;
  width: calc(100% - 72px);
}
@media (max-width: 959px) {
  #register .register-select-btn {
    width: 100%;
    position: static;
    margin-top: 32px;
  }
}
#register .register-select-btn .btn {
  width: 100%;
}
@media (max-width: 559px) {
  #register .register-select-btn .btn {
    width: calc(100% + 48px);
    margin-left: -24px;
  }
}
#register .register-select-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 46px - 120px);
  top: -46px;
  background: #FFF500;
  color: #181818;
  font-size: 1.8rem;
  font-weight: 500;
}
@media (max-width: 559px) {
  #register .register-select-popup {
    width: 68px;
    height: 68px;
    left: calc(50% - 34px - 100px);
    font-size: 1.4rem;
  }
}
#register .register-select-popup:before {
  content: "";
  width: 18px;
  height: 18px;
  background: #FFF500;
  transform: skew(40deg) rotate(17deg);
  position: absolute;
  bottom: 6px;
  right: 6px;
  z-index: 1;
}
#register .register-select-tb {
  font-size: 1.6rem;
}
@media (max-width: 959px) {
  #register .register-select-tb {
    font-size: 1.5rem;
  }
}
@media (max-width: 559px) {
  #register .register-select-tb {
    font-size: 1.2rem;
    line-height: 1.3;
    width: 100vw;
    margin-left: -10vw;
  }
}
#register .register-select-tb th, #register .register-select-tb td {
  padding: 14px 10px;
  border-bottom: 1px solid #888;
  border-left: 1px solid #888;
  vertical-align: middle;
}
#register .register-select-tb th:first-child, #register .register-select-tb td:first-child {
  border-left: none;
}
@media (max-width: 559px) {
  #register .register-select-tb th, #register .register-select-tb td {
    padding: 10px;
  }
}
#register .register-select-tb thead th {
  background: #363636;
}
#register .register-select-tb tbody td {
  text-align: center;
}
#register .register-select .product-user {
  border: none;
  padding: 36px 36px 90px;
}
@media (max-width: 959px) {
  #register .register-select .product-user {
    margin-top: 0;
  }
}
@media (max-width: 559px) {
  #register .register-select .product-user {
    padding: 24px 24px 0;
  }
}
#register .register-select .product-user .btn {
  font-size: 1.4rem;
  margin: 0 auto;
  width: 90%;
}
@media (max-width: 559px) {
  #register .register-select .product-user .btn {
    width: calc(100% + 48px);
    margin: initial;
    margin-left: -24px;
    font-size: 1.2rem;
  }
}
#register .register-select .product-user .btn::after {
  width: 14px;
}
#register .register-select .product-user .btn .btn-break {
  display: block;
  padding-top: 5px;
}
#register .register-select .product-user .btn .btn-label {
  text-align: center;
}
#register .box-wide {
  width: 100%;
}
#register .mypage-user .register-select-tit {
  background: url(../images/icon_mypage_user.png) center top no-repeat;
  background-size: 150px 70px;
}
@media (max-width: 559px) {
  #register .mypage-user {
    padding: 0;
  }
}
@media (max-width: 559px) {
  #register .mypage-user .box-wrap {
    padding: 24px 24px 0;
  }
}
#register .mypage-user .register-select-btn {
  display: grid;
  place-content: center;
}
@media (max-width: 959px) {
  #register .mypage-user .register-select-btn {
    place-content: unset;
  }
}
#register .mypage-user .register-select-btn .btn {
  width: 500px;
}
@media (max-width: 959px) {
  #register .mypage-user .register-select-btn .btn {
    width: 100%;
  }
}
@media (max-width: 559px) {
  #register .mypage-user .register-select-btn .btn {
    margin-left: 0;
  }
}
@media (max-width: 959px) {
  #register .product-user {
    margin-top: 40px;
  }
}
#register .product-user .register-select-tit {
  background: url(../images/icon_product_user.png) center top no-repeat;
  background-size: 80px 70px;
}
#register .register-mail-tb {
  width: 700px;
  margin: 0 auto;
}
@media (max-width: 959px) {
  #register .register-mail-tb {
    width: 100%;
  }
}
#register .register-mail-tb th {
  width: 280px;
}
@media (max-width: 959px) {
  #register .register-mail-tb th {
    width: 230px;
  }
  #register .register-mail-tb th .s {
    font-size: 1.2rem;
  }
}
#register .register-input-tb {
  width: 900px;
  margin: 0 auto;
}
@media (max-width: 959px) {
  #register .register-input-tb {
    width: 100%;
  }
}
#register .register-input-tb th {
  width: 250px;
  position: relative;
}
@media (max-width: 959px) {
  #register .register-input-tb th {
    width: 180px;
  }
}
@media (max-width: 559px) {
  #register .register-input-tb th {
    width: 100%;
  }
}
#register .banner-change {
  width: 720px;
  margin: 80px auto 0;
  font-size: 1.8rem;
}
@media (max-width: 959px) {
  #register .banner-change {
    width: 100%;
    font-size: 1.7rem;
    margin-top: 60px;
  }
}
@media (max-width: 559px) {
  #register .banner-change {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
#register .banner-change a {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #888;
  background: #181818;
  text-align: center;
  transition: background 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
#register .banner-change a:hover {
  text-decoration: none;
  background: #383838;
}
#register .change-usertype-tb {
  width: 700px;
}
@media (max-width: 959px) {
  #register .change-usertype-tb {
    width: 100%;
  }
}
#register .law-contents dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 30px 40px 30px;
  line-height: 2;
}
@media (max-width: 559px) {
  #register .law-contents dl {
    display: block;
    padding: 0px 0px 30px 0px;
    line-height: 2;
  }
}
#register .law-contents dt {
  width: 220px;
  font-weight: 600;
}
@media (max-width: 559px) {
  #register .law-contents dt {
    width: 100%;
  }
}
#register .law-contents dd {
  width: calc(100% - 220px);
}
@media (max-width: 559px) {
  #register .law-contents dd {
    width: 100%;
  }
}

.register-product {
  margin: 60px 0;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
  padding: 40px 0;
}
@media (max-width: 559px) {
  .register-product {
    margin: 30px 0;
  }
}
.register-product-tit {
  font-size: 2.4rem;
}
@media (max-width: 559px) {
  .register-product-tit {
    font-size: 2.1rem;
  }
}
.register-product-box {
  background: #363636;
  padding: 20px 30px;
  margin-top: 16px;
}
@media (max-width: 559px) {
  .register-product-box {
    padding: 5px 15px;
  }
}
.register-product-text {
  margin-top: 20px;
}
@media (max-width: 559px) {
  .register-product-text {
    margin-top: 10px;
  }
}
.register-product-tb th, .register-product-tb td {
  padding: 8px 0 !important;
}
.register-product-tb th {
  padding-top: 14px !important;
  width: 250px;
}
@media (max-width: 959px) {
  .register-product-tb th {
    width: 140px !important;
  }
}
.register-product-tb tr {
  padding: 8px 0 !important;
}
.register-product-btn {
  margin-top: 10px;
}
@media (max-width: 559px) {
  .register-product-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .register-product-btn .btn-delete {
    margin-top: -15px;
  }
}

.register-product-list {
  background: #181818;
  border: 1px solid #888;
  padding: 10px;
  display: block;
  width: 900px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 10;
}
@media (max-width: 959px) {
  .register-product-list {
    width: calc(80vw - 60px);
    height: 320px;
    overflow-x: hidden;
  }
}
@media (max-width: 559px) {
  .register-product-list {
    width: calc(96vw - 30px);
    height: 250px;
    padding: 5px;
  }
}
.register-product-list.is-show {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 559px) {
  .register-product-list.is-show {
    right: -8vw;
  }
}
.register-product-list ul {
  display: flex;
  flex-wrap: wrap;
}
.register-product-list ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px !important;
  width: 25%;
  margin-top: 0 !important;
}
@media (max-width: 959px) {
  .register-product-list ul li {
    width: 33%;
  }
}
@media (max-width: 559px) {
  .register-product-list ul li {
    width: 50%;
    padding: 4px !important;
  }
}
.register-product-list ul li:before {
  display: none !important;
}
.register-product-list ul li:hover {
  background: #363636;
}
.register-product-list .register-product-thumb {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #fff;
  position: relative;
}
.register-product-list .register-product-label {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  padding-left: 10px;
  font-size: 1.2rem;
  line-height: 1.3;
}
@media (max-width: 559px) {
  .register-product-list .register-product-label {
    font-size: 1rem;
    padding-left: 5px;
  }
}
.register-product-list .register-product-image {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: 999;
  width: 250px;
  height: 250px;
  border: 2px solid #181818;
}
.register-product-list .register-product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#faq .faq-tit {
  font-size: 3.2rem;
  text-align: center;
}
@media (max-width: 559px) {
  #faq .faq-tit {
    font-size: 2.1rem;
  }
}
#faq .faq-subtit {
  margin-bottom: 20px;
}
#faq .faq-box {
  border-bottom: 1px solid #363636;
  margin-top: 0;
}
#faq .faq-q {
  display: flex;
  align-items: center;
  padding-right: 40px;
  color: #fff;
  position: relative;
  transition: background 0.2s ease, color 0.2s ease;
  cursor: pointer;
}
@media (max-width: 559px) {
  #faq .faq-q {
    display: block;
    padding-right: 25px;
  }
}
#faq .faq-q .icon-faq {
  display: inline-block;
  font-size: 2.8rem;
  width: 40px;
  height: 50px;
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 559px) {
  #faq .faq-q .icon-faq {
    font-size: 2.1rem;
    height: auto;
    text-align: left;
  }
}
#faq .faq-q .faq-q-text {
  display: block;
  width: calc(100% - 40px);
  padding: 10px;
  line-height: 1.5;
}
@media (max-width: 559px) {
  #faq .faq-q .faq-q-text {
    padding: 5px 0 10px;
    width: 100%;
    font-size: 1.4rem;
  }
}
#faq .faq-q:before {
  content: "";
  width: 12px;
  height: 12px;
  border-right: 1px solid #888;
  border-bottom: 1px solid #888;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.2s ease;
}
#faq .faq-q:hover {
  background: #222;
  color: #999;
}
@media (max-width: 559px) {
  #faq .faq-q:hover {
    background: none;
    color: inherit;
  }
}
#faq .faq-q:hover:before {
  border-color: #fff;
  top: calc(50% + 5px);
}
#faq .faq-q.faq-open:before {
  transform: translateY(-50%) rotate(45deg) scale(-1, -1);
}
#faq .faq-a {
  display: none;
  padding: 10px 0 20px 50px;
  font-size: 1.5rem;
  line-height: 1.8;
}
@media (max-width: 559px) {
  #faq .faq-a {
    padding: 0 0 10px 0;
    font-size: 1.3rem;
  }
}

#top .top-info-tit {
  font-size: 2.4rem;
}
@media (max-width: 559px) {
  #top .top-info-tit {
    font-size: 1.9rem;
  }
}
#top .top-info-tit .s {
  font-size: 1.9rem;
  margin: 0 3px;
}
@media (max-width: 559px) {
  #top .top-info-tit .s {
    font-size: 1.6rem;
  }
}

#item .register-product {
  border: none;
  padding: 0;
}
#item .item-add-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
}
@media (max-width: 959px) {
  #item .item-add-btn {
    margin-top: -50px;
  }
  #item .item-add-btn .btn {
    width: 300px;
  }
}
@media (max-width: 559px) {
  #item .item-add-btn {
    margin-top: 20px;
  }
}
@media (max-width: 959px) {
  #item .item-list-tb {
    font-size: 1.4rem;
  }
}
#item .item-list-tb th, #item .item-list-tb td {
  vertical-align: middle;
}
#item .item-list-tb .th4 {
  width: 140px;
}
@media (max-width: 959px) {
  #item .item-list-tb .th4 {
    width: 110px;
  }
}
#item .item-list-tb .th5 {
  width: 100px;
}
@media (max-width: 959px) {
  #item .item-list-tb .th5 {
    width: 70px;
  }
}
#item .item-list-tb tbody td:last-child {
  text-align: center;
}
@media (max-width: 959px) {
  #item .item-list-tb tbody td:last-child {
    padding: 16px 0;
  }
}
@media (max-width: 559px) {
  #item .item-list-tb tbody td:last-child {
    padding: 0;
    width: 45px;
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
}
#item .item-list-tb .list-check {
  text-align: center;
}
@media (max-width: 959px) {
  #item .item-list-tb .list-check {
    padding: 16px 0;
  }
}
@media (max-width: 559px) {
  #item .item-list-tb .list-check {
    padding: 0;
    width: 20px;
    position: absolute;
    left: 20px;
    top: 10px;
  }
}
@media (max-width: 559px) {
  #item .item-list-tb {
    width: 100vw;
    margin-left: -10vw;
  }
  #item .item-list-tb thead {
    display: none;
  }
  #item .item-list-tb tr {
    position: relative;
    padding: 15px 20px;
  }
  #item .item-list-tb .list-check + th {
    padding-left: 25px !important;
    font-size: 1.3rem;
  }
  #item .item-list-tb td[data-label] {
    font-size: 1.1rem;
    padding-left: 25px !important;
    width: calc(100% - 60px);
  }
  #item .item-list-tb td[data-label]:before {
    content: attr(data-label) "：";
  }
}

#product .register-product {
  border: none;
  padding: 0;
}

#user .user-input-tb tbody th {
  width: 250px;
}

#mail .mail-input-tb tbody th {
  width: 250px;
}

#information .info-detail-tit h2 {
  font-size: 2.4rem;
  line-height: 1.3;
}
@media (max-width: 559px) {
  #information .info-detail-tit h2 {
    font-size: 1.8rem;
  }
}
#information .info-detail-date {
  font-size: 1.4rem;
  color: #999;
  margin-top: 10px;
}
@media (max-width: 559px) {
  #information .info-detail-date {
    font-size: 1.2rem;
  }
}
#information .info-detail-image {
  width: 768px;
  margin: 40px auto;
}
@media (max-width: 959px) {
  #information .info-detail-image {
    width: 100%;
  }
}
#information .info-detail-image img {
  max-width: 100%;
  height: auto;
}

/*exValidation*/
.errMsg {
  margin-left: 1em;
  padding-bottom: 1px;
  display: block;
  line-height: 1;
  text-indent: -0.9em;
}

.formError {
  padding-bottom: 5px;
  display: block;
  margin-left: -20px;
}
@media (max-width: 559px) {
  .formError {
    margin-left: -50px;
  }
}
.formError .formErrorContent {
  padding: 4px 6px;
  width: auto;
  position: relative;
  color: #fff;
  background: #D93732;
  font-size: 1.1rem;
  _z-index: 1;
  border-radius: 3px;
}
.formError .formErrorArrow {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -10px;
  left: 3pxpx;
  _z-index: 0;
}
.formError .formErrorArrow div {
  margin: 0 auto;
  display: block;
  height: 1px;
  background: #D93732;
  line-height: 0px;
  font-size: 0px;
}
.formError .formErrorArrow .line10 {
  width: 19px;
}
.formError .formErrorArrow .line9 {
  width: 17px;
}
.formError .formErrorArrow .line8 {
  width: 15px;
}
.formError .formErrorArrow .line7 {
  width: 13px;
}
.formError .formErrorArrow .line6 {
  width: 11px;
}
.formError .formErrorArrow .line5 {
  width: 9px;
}
.formError .formErrorArrow .line4 {
  width: 7px;
}
.formError .formErrorArrow .line3 {
  width: 5px;
}
.formError .formErrorArrow .line2 {
  width: 3px;
}
.formError .formErrorArrow .line1 {
  width: 1px;
}
.formError .fadeOut {
  opacity: 0.2;
}

.err, .chkgroup.err input, .chkgroup.err select {
  border-color: #D93732 !important;
}

#fpurpose.err {
  position: relative;
}
#fpurpose.err:before {
  content: "";
  width: calc(100% + 10px);
  height: calc(100% + 20px);
  position: absolute;
  top: 0;
  left: -10px;
  border: 1px solid #D93732;
}
#fpurpose.err input[type=text] {
  border-color: #999 !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media print {
  body {
    zoom: 0.65;
  }
  #wrapper {
    min-width: 0;
  }
  .anm {
    opacity: 1;
    top: 0;
  }
}